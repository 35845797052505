<template>
    <v-row>
      <v-col cols="12">
          <sale-per-day-card> </sale-per-day-card> 
      </v-col>
    </v-row>
  </template>
  <script>
  import SalePerDayCard from "@/components/report/SalePerDayCard";
  
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Sales Per Day",
    },
  
    components: {
       SalePerDayCard
    },
  
    data() {
      return {
      };
    },
  };
  </script>